div[data-react-class='SongDisplay'] {
  display: initial;
}

.song-container {
  text-align: center;
}

.title-number {
  display: inline-block;
  color: #aaa;
  margin-left: 15px;
  margin-bottom: 15px;
}

.top-section {
  flex-direction: column;
}

.related-songs {
  background-color: #fff;
}

.lyrics {
  width: auto;
  margin-left: 20px;
  display: inline-block;
  text-align: left;

  .capo {
    user-select: none;
  }
  .transpose-symbol {
    position: relative;
    top: 1px;
    width: 18px;
    padding: 2px;
    display: inline-block;
    font-weight: 900;
    color: #777;
    opacity: 50%;
    font-size: 1.5em;
    border: 1px solid;
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .musical-tie {
    width: 5px;
    display: inline-block;
    position: relative;
    top: 3px;
    left: -2px;
  }
  .comment, #transpose-preset {
    margin-bottom: 8px;
    color: darkgray;
    font-size: 90%;
  }
  .comment2, #transpose-preset {
    margin-bottom: 8px;
    color: transparent;
    font-size: 90%;
  }
  #transpose-preset {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .transpose-controls {
    color: darkgray;
  }
  .song-controls {
    margin-bottom: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    div {
      display: inline-block;
    }
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .chord-word {
    display: inline-block;
    padding-top: 17px;
    height: 17px;
    position: relative;
    .chord {
      color: #1f45ff;
      white-space: pre;
      position: absolute;
      bottom: 17px;
      font-weight: normal;
      font-style: normal;
    }
    &::after { // Empty chord-words must have content to align the absolute chords
      content: "";
      display: inline-block;
    }
  }
  .chord-word-hide {
    display: inline-block;
    position: relative;
    .chord-hide {
      color: transparent;
      position: absolute;
    }
    &::after { // Empty chord-words must have content to align the absolute chords
      content: "";
      display: inline-block;
    }
  }
  .bookmark {
    display: none !important; // haven't finished any function!
    position: absolute;
    top: 0px;
    right: 32%;
  }
  .line {
    min-height: 12px;
  }

  .line-text {
    vertical-align: bottom;
    white-space: pre-wrap;
  }

  // space between verses
  .line-text:empty {
    margin-top: 8px;
    display: block;
  }

  .chorus {
    margin: 0 0 10px 25px;
  }

  .verse-number {
    position: absolute;
    margin-left: -25px;
  }
}

[data-uncopyable-text]::after {
  content: attr(data-uncopyable-text);
}