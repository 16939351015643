h1 {
    margin-top: 50px;
}

ul {
    cursor: pointer;
    margin: 0px;
    padding: 5px;
    align-content: flex-start;
    list-style: none;
    text-align: left;
}

.search-input{
    max-width: 500px;
    min-width: 320px;
    height: 40px;
    border-width: 1px;
    border-color: #444;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 18px;
    border-radius: 4px;
}

.home-div {
    display: flex;
    justify-content: center;
}

.list-body {
    max-width: 500px;
    min-width: 320px;
}

.list-item:hover {
    font-weight: 700;
}

.list-item {
    width: 100%;
}

.song-number {
    width: 90px;
    text-align: end;
    padding-right: 5px;
}

.list-item-grey-bg {
    background-color: #eee;
}

.list-item-white-bg {
    background-color: #fff;
}

.song-list-item {
    display: flex;
    flex-direction: row;
}


/* Song Detail page */
.detail-body {
    flex-direction: column; 
    display: flex;
    align-items: center;
}

.song-text-body {
    max-width: 500px;
    min-width: 320px;
    text-align: left;
}

.medias {
    margin-top: 30px;
}

.audio-player {
    height: 100px;
    max-width: 500px;
    min-width: 320px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.video-player {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 500px;
    min-width: 320px;
}
.media-title {
    margin-bottom: 7px;
}
.info-icon {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}
.other-buttons {
    flex-direction: row; 
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.popup-content {
    margin: -5px;
    background: rgb(255, 255, 255);
    max-width: 500px;
    min-width: 320px;
    padding: 5px;
}

.music-popup-content {
    margin: -5px;
    background: rgb(255, 255, 255);
    max-width: 500px;
    min-width: 320px;
    padding: 5px;
}

.music-sheet {
    max-width: 500px;
    min-width: 320px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto;
}

.close {
    color: black;
    font-size: 17px;
    position: absolute;
    right: -10px;
    top: -10px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border-width: 1px;
    border-color: black;
    padding: 0px;
    margin: 0px;
}

.back-icon-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border: none;
}

.back-icon {
    height: 30px;
    width: 30px;
}

.guitar-icon-button {
    position: relative;
    margin-left: 280px;
    background-color: transparent;
    border: none;
}

.guitar-icon {
    height: 30px;
    width: 30px;
}

.related-songs {
    flex-direction: row;
    /* max-width: 500px;
    min-width: 320px;; */
}

.related-songs-link {
    margin-left: 5px;
    cursor: pointer;
}

.capo-text {
    text-align: end;
    width: 320px;
}

h5 {
    display: inline;
    text-decoration: underline;
}

.setting-icon{
    position: fixed;
    height: 25px;
    width: 25px;
    color: #333;
    right: 10px;
    top: 5px;
}

.lang-bg {
    background-color: #fff;
    max-width: 500px;
    min-width: 320px;
    /* height: 400px; */
}

h3 {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox-input{
    padding: 5px;
}

.apply-button-container {
    display: flex;
    padding-top: 10px;
}

.apply-button {
    height: 30px;
    width: 60px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.clear-button {
    height: 30px;
    width: 120px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 8px;
}

.copyright-text {
    font-size: 10;
    font-style: italic;
}